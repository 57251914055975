import React from 'react';
import "./Page5.css"

export default function Page5({data}) {
  console.log("jojerofre",data?.personal)
  return (
    <div class=" page-4 page-break-1">
    <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>
    <div
      className="head-managereport d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <img src="/assets/img/logo.png" className="head-managereport-logo" />
      <div className="head-managereport-text1">Nutrition Management</div>
      <div
        className="text-flex-line-center-veritcal"
        style={{ color: "rgba(84, 84, 84, 1)" }}
      >
        Smart Report
      </div>
    </div>

    <div class="foodhair-box" style={{gap : "3%",padding : "30px"}}>
       <div class="foodcard-img teijsie-2" >
          <div class="hairloss-img" style={{height : "10%"}}>
            <img src="/assets/img/reports/management/page5/Group 1707485989.png" alt="" class="hairloss-img1" />
          </div>
          <div class="food-text">
            <h4 class="food-subtxt">
              Foods for Hair Loss
            </h4>
          </div>
          <div class="food-content">
            <div class="management-report-page5-nutrition-mngmnt2-text172">
              Boost hair health and growth with these nutrient-rich options:
            </div>

            <div class="management-report-page5-nutrition-mngmnt2-text175">
            <span class="management-report-page5-nutrition-mngmnt2-text174">
              Protein
            </span>
              : Eggs, fish, poultry, beans, lentils, tofu, Greek yogurt.
            </div>

            

            <div class="management-report-page5-nutrition-mngmnt2-text178">
            <span class="management-report-page5-nutrition-mngmnt2-text177">
              Iron
            </span>
              : Spinach, kale, lentils, beans, red meat, fortified cereals.
            </div>
            

            <div class="management-report-page5-nutrition-mngmnt2-text181">
            <span class="management-report-page5-nutrition-mngmnt2-text180">
              Biotin
            </span>
              : Eggs, nuts, seeds, sweet potatoes, avocados.
            </div>
            
            <div class="management-report-page5-nutrition-mngmnt2-text183">
              Omega-3: Fatty fish, chia seeds, flaxseeds.
            </div>
            

            <div class="management-report-page5-nutrition-mngmnt2-text186">
            <span class="management-report-page5-nutrition-mngmnt2-text185">
              Vitamin C
            </span>
              : Citrus, strawberries, kiwi, bell peppers, broccoli.
            </div>
            

            <div class="management-report-page5-nutrition-mngmnt2-text189">
            <span class="management-report-page5-nutrition-mngmnt2-text188">
              Zinc
            </span>
              : Oysters, beef, pumpkin seeds, chickpeas.
            </div>
            

            <div class="management-report-page5-nutrition-mngmnt2-text192">
            <span class="management-report-page5-nutrition-mngmnt2-text191">
              Selenium
            </span>
              : Brazil nuts, seafood, whole grains.
            </div>
            

            <div class="management-report-page5-nutrition-mngmnt2-text195">
            <span class="management-report-page5-nutrition-mngmnt2-text194">
              Antioxidants
            </span>
              : Berries, colorful veggies, green tea.
            </div>
            

            <div class="management-report-page5-nutrition-mngmnt2-text198">
            <span class="management-report-page5-nutrition-mngmnt2-text197">
              Silica
            </span>
              : Cucumbers, bell peppers, whole grains.
            </div>
            

            <div class="management-report-page5-nutrition-mngmnt2-text201">
            <span class="management-report-page5-nutrition-mngmnt2-text200">
              B-Vitamins
            </span>
              : Whole grains, nuts, seeds, leafy greens.
            </div>
            

            <div class="management-report-page5-nutrition-mngmnt2-text204">
            <span class="management-report-page5-nutrition-mngmnt2-text203">
              Collagen
            </span>
              : Bone broth, collagen supplements, gelatin-rich foods.
            </div>
            
            <div class="management-report-page5-nutrition-mngmnt2-text206">
              Hydration: Drink plenty of water daily.
            </div>
            
          </div>
       </div>
        <div class="foodcard-img2 teijsie-2" >
          <div class="hairloss-img" style={{height : "10%"}}>
            <img src="/assets/img/reports/management/page5/Group 1707485988.png" alt="" class="hairloss-img1" />
          </div>
          <div class="food-text">
            <h4 class="food-subtxt">
              Foods to Avoid for Hair Health
            </h4>
          </div>
          <div class="food-content">
            <span class="management-report-page5-nutrition-mngmnt2-text105">
              Processed Foods
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text106">
              : Refined sugars and unhealthy fats cause
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text108">
              inflammation.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text110">
              Sugary Items
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text111">
              : Spike insulin, impacting hormones and hair health.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text113">
              Excess Alcohol
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text114">
              : Leads to nutrient loss and dehydration.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text116">
              High Sodium
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text117">
              : Reduces circulation, affecting hair growth.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text119">
              Trans Fats
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text120">
              : Promote inflammation, hindering hair growth.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text122">
              Artificial Additives
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text123">
              : Negatively impact hair and overall health.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text125">
              Excess Caffeine
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text126">
              : Dehydrates and limits nutrient absorption.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text128">
              Soy in Large Amounts
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text129">
              : Can disrupt hormones, affecting hair.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text131">
              Excess Vitamin A:
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text132">
              Can cause hair loss.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text134">
              Raw Egg Whites
            </span>
            <span class="management-report-page5-nutrition-mngmnt2-text135">
              : Interfere with biotin absorption.
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text137">
              Crash Diets
            </span>
            <span>: Lead to nutrient deficiencies and hair loss.</span>
            
          </div>
        </div>
    </div>

    <div style={{display : "flex",justifyContent:"center"}}>
    <div class="before-title">
        Throughout the day, make sure to stay hydrated by drinking plenty
        of water. Additionally, consider including herbal teas like green
        tea or peppermint tea, which can contribute to scalp health.
    </div>
    </div>

    <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)",marginTop : "7%"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>


    </div>





    <div className='d-flex flex-column page-break-1' style={{justifyContent : "space-between",marginTop : "0"}}>
   
    <div
      className="head-managereport d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <img src="/assets/img/logo.png" className="head-managereport-logo" />
      <div className="head-managereport-text1">Nutrition Management</div>
      <div
        className="text-flex-line-center-veritcal"
        style={{ color: "rgba(84, 84, 84, 1)" }}
      >
        Smart Report
      </div>
    </div>

    <div style={{display : "flex",justifyContent : "center"}} className='mt-2'> 
    <div class="faq-titledesign" style={{width : "70%"}}>
      <div class="faq-box1">
      </div>
      <div class="faq-box2">
        <div class="faq-boxdesign">
          <div class="faq-box3"></div>
          <div class="faq-box4"></div>
        </div>
        <span class="faq-title">
          FREQUENTLY ASKED QUESTIONS FOR HAIR LOSS &amp; HAIR THINNING?
        </span>
        <div class="faq-boxdesign">
          <div class="faq-box3"></div>
          <div class="faq-box4"></div>
        </div>
      </div>
      <div class="faq-box6">
      </div>
    </div>
    </div>


    
    <div class="faq-boxs" style={{gap : "3%",padding : "30px"}}>
        <div class="faq-img teijsie-2" style={{height : "575px"}}>
          <span class="faq-content">

            <div
              class="management-report-page5-nutrition-mngmnt2-text145"
            >
                          <span class="management-report-page5-nutrition-mngmnt2-text141">
              1. Can diet help prevent hair loss?
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text143">
              Yes, a balanced diet rich in protein, iron, biotin, zinc, and
              omega-3 fatty acids can support hair health and may prevent some
              hair loss.
            </span>
            

            </div>
            

            
            <div
              class="management-report-page5-nutrition-mngmnt2-text151"
            >
                          <span class="management-report-page5-nutrition-mngmnt2-text147">
              2. What nutrients are important for hair health?
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text149">
              Protein, iron, biotin, zinc, omega-3 fatty acids, vitamin C, and
              vitamin E are crucial for hair health.
            </span>
            </div>
            

            
            <div
              class="management-report-page5-nutrition-mngmnt2-text157"
            >
                          <span class="management-report-page5-nutrition-mngmnt2-text153">
              3. Can nutrient deficiencies cause hair loss?
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text155">
              Yes, deficiencies in iron, biotin, and zinc can lead to hair loss
              and thinning.
            </span>
            </div>
            

            
            <div
              class="management-report-page5-nutrition-mngmnt2-text163"
            >
                          <span class="management-report-page5-nutrition-mngmnt2-text159">
              4. What foods promote hair growth?
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text161">
              Foods rich in protein (eggs, legumes, nuts), iron (spinach,
              lentils), biotin (avocado, nuts), and omega-3 fatty acids (fatty
              fish, chia seeds).
            </span>
            </div>
            

            
            <div>
            <span class="management-report-page5-nutrition-mngmnt2-text165">
              5. Can diet help with hair regrowth?
            </span>
            
            <span>
              A nutrient-rich diet may support hair regrowth, especially if hair
              loss is due to nutritional deficiencies, but it may not be
              sufficient for everyone.
            </span>
            </div>
            
          </span>
        </div>
        <div class="faq-img teijsie-2"  style={{height : "575px"}} >
          <span class="faq-content">

            
            <div
              class="management-report-page5-nutrition-mngmnt2-text214"
            >
                          <span class="management-report-page5-nutrition-mngmnt2-text210">
              6. Are there foods that worsen hair loss?
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text212">
              Yes, foods high in processed sugars, unhealthy fats, and
              additives, as well as excessive alcohol and crash diets, can
              worsen hair loss.
            </span>
            </div>
            

            
            <div
              class="management-report-page5-nutrition-mngmnt2-text220"
            >
                          <span class="management-report-page5-nutrition-mngmnt2-text216">
              7. Can vegan or vegetarian diets contribute to hair loss?
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text218">
              Poorly planned vegan or vegetarian diets lacking vitamin B12,
              iron, and protein can contribute to hair loss.
            </span>
            </div>
            

            
            <div
              class="management-report-page5-nutrition-mngmnt2-text226"
            >
                          <span class="management-report-page5-nutrition-mngmnt2-text222">
              8. Can supplements help with hair loss?
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text224">
              Supplements with biotin, iron, and zinc may help, but should be
              used under healthcare guidance to avoid excess intake.
            </span>
            </div>
            

            
            <div
              class="management-report-page5-nutrition-mngmnt2-text234"
            >
                          <span class="management-report-page5-nutrition-mngmnt2-text228">
              9. Does stress affect hair loss, and can diet help manage stress?
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text230">
              Yes, stress can contribute to hair loss. A balanced diet rich in
              B-
            </span>
            
            <span class="management-report-page5-nutrition-mngmnt2-text232">
              vitamins and magnesium can help manage stress.
            </span>
            </div>
                <div>
                <span class="management-report-page5-nutrition-mngmnt2-text236">
              10. How long to see improvements with a hair loss diet?
            </span>
            
            <span>
              Improvements may take several weeks to months with a nutrient-rich
              diet.
            </span>
                </div>
            
          </span>
        </div>
    </div>

    <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)",marginTop : "4.5%"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>
    </div>





      
    </div>
    
  );
}
