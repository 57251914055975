import React from "react";
import "./Page3.css";

export default function Page3({ data }) {


  const content = {
    // "Normal Scalp": {
    //   heading: "SCALP",
    //   title: "Normal Scalp",
    //   paragraphs: [
    //     {
    //       strong: "",
    //       text: "As per your inputs and analysis your scalp condition looks normal. ",
    //     },
    //   ],
    // },
    // "Good": {
    //   heading: "Hair Quality",
    //   title: "Good",
    //   paragraphs: [
    //     {
    //       strong: "",
    //       text: "As per your inputs and analysis your Hair Quality condition looks Good. ",
    //     },
    //   ],
    // },
  
    
    // "hairdensity good": {
    //   heading: "Hair Density",
    //   title: "Good",
    //   paragraphs: [
    //     {
    //       strong: "",
    //       text: "As per your inputs and analysis your Hair Density condition looks Good. ",
    //     },
    //   ],
    // },
    // "Normal Hair Color": {
    //   heading: "Hair Color Vibrancy",
    //   title: "Normal Hair Color",
    //   paragraphs: [
    //     {
    //       strong: "",
    //       text: "As per your inputs and analysis your Hair Color Vibrancy condition looks Normal. ",
    //     },
    //   ],
    // },
    // "Well Hydrated": {
    //   heading: "Moisture and hydration of hair",
    //   title: "Well Hydrated",
    //   paragraphs: [
    //     {
    //       strong: "",
    //       text: "As per your inputs and analysis your Moisture and hydration of hair condition looks Normal. ",
    //     },
    //   ],
    // },
    // "No Breakage": {
    //   heading: "Hair Breakage",
    //   title: "No Breakage",
    //   paragraphs: [
    //     {
    //       strong: "",
    //       text: "As per your inputs and analysis your Hair Breakage condition looks Normal. ",
    //     },
    //   ],
    // },
  
    "Oily Scalp": {
      heading: "SCALP",
      title: "Oily Scalp",
      paragraphs: [
        {
          strong: "",
          text: "Managing an oily scalp can be achieved through a combination of proper hair care, lifestyle adjustments, and the use of suitable hair products. Here are some tips to effectively manage an oily scalp:      ",
        },
        {
          strong: "1) Frequent Shampooing: ",
          text: "Use a gentle shampoo designed for oily hair to remove excess sebum.      ",
        },
        {
          strong: "2) Avoid Hot Water: ",
          text: "Wash with lukewarm water to prevent stimulating more oil production.",
        },
        {
          strong: "3) Clarifying Shampoo: ",
          text: "Use a clarifying shampoo weekly to deep-clean and remove buildup.   ",
        },
        {
          strong: "4) Scalp Focus: ",
          text: "Massage shampoo into the scalp for thorough cleansing.",
        },
        {
          strong: "5) Proper Conditioning: ",
          text: "Avoid applying conditioner to the scalp; focus on mid-lengths to ends. ",
        },
        {
          strong: "6) Choose Lightweight Products: ",
          text: "Opt for oil-free and non-comedogenic hair products.",
        },
        {
          strong: "7) Limit Brushing: : ",
          text: "Excessive brushing can distribute oil; brush as needed.      ",
        },
        {
          strong: "8) Minimize Touching: ",
          text: "Avoid unnecessary touching to prevent transferring oil.",
        },
        {
          strong: "9) Oil-Absorbing Papers: ",
          text: "Carry blotting papers or dry shampoo for oil control on-the-go.",
        },
        {
          strong: "10) Avoid Heavy Styling Products: ",
          text: "Use lightweight serums or gels recommended for oily scalps.",
        },
        {
          strong: "11) Watch Your Diet: ",
          text: "Limit greasy and fried foods that may increase oil production.",
        },
        {
          strong: "12) Stay Hydrated: ",
          text: "Drink water to regulate oil production and maintain scalp health.",
        },
        {
          strong: "13) Limit Heat Styling: ",
          text: "Air dry hair when possible and use low heat settings on styling tools.",
        },
      ],
    },
    "Dry Scalp": {
      heading: "SCALP",
      title: "Dry Scalp",
      paragraphs: [
        {
          strong: "",
          text: "Managing a dry scalp involves incorporating proper hair care practices and making lifestyle adjustments to promote scalp health and hydration. Here are some tips to effectively manage dry scalp:",
        },
        {
          strong: "1) Moisturizing Products: ",
          text: "Use shampoo and conditioner designed for dry scalp with hydrating ingredients like glycerin or coconut oil.",
        },
        {
          strong: "2) Avoid Hot Water: ",
          text: "Wash with lukewarm water to prevent stripping natural oils.",
        },
        {
          strong: "3) Shampoo Less Often: ",
          text: "Limit washing to every other day or as needed to avoid drying out the scalp.",
        },
        {
          strong: "4) Scalp Massage: ",
          text: "Stimulate blood flow by gently massaging the scalp while shampooing.",
        },
        {
          strong: "5) Scalp Oil Treatment: ",
          text: "Apply nourishing oils like jojoba or argan once a week for extra hydration.",
        },
        {
          strong: "6) Avoid Harsh Products: ",
          text: "Stay away from alcohol or chemical-laden hair products.",
        },
        {
          strong: "7) Deep Conditioning: ",
          text: "Use a hair mask regularly to restore moisture.",
        },
        {
          strong: "8) Limit Heat Styling: ",
          text: "Reduce use of heat tools and apply a heat protectant when necessary.",
        },
        {
          strong: "9) Stay Hydrated: ",
          text: "Drink plenty of water to maintain scalp hydration.",
        },
        {
          strong: "10) Healthy Diet: ",
          text: "Eat foods rich in omega-3 fatty acids for scalp health.",
        },
        {
          strong: "11) Avoid Scratching: ",
          text: "Resist scratching to prevent irritation.",
        },
        {
          strong: "12) Weather Protection: ",
          text: "Shield scalp from harsh elements with hats or scarves.",
        },
        {
          strong: "13) Limit Chemical Treatments: ",
          text: "Minimize use of perms or relaxers to avoid further dryness.",
        },
      ],
    },
    "Dry & Flaky Scalp": {
      heading: "SCALP",
      title: "Dry & Flaky Scalp",
      paragraphs: [
        {
          strong: "",
          text: "Managing a dry and flaky scalp requires a combination of scalp care, hair care, and lifestyle adjustments to promote hydration and alleviate flakiness. Here are some tips for effectively managing a dry and flaky scalp:",
        },
        {
          strong: "1) Moisturizing Products: ",
          text: "Use shampoo and conditioner designed for dry scalps with soothing ingredients like aloe vera or tea tree oil.",
        },
        {
          strong: "2) Limit Shampooing: ",
          text: "Avoid daily washing to prevent stripping natural oils; wash every other day or as needed.",
        },
        {
          strong: "3) Scalp Massage: ",
          text: "Stimulate blood flow by gently massaging while shampooing to enhance product absorption.",
        },
        {
          strong: "4) Scalp Oil Treatment: ",
          text: "Apply a nourishing scalp oil once or twice weekly with oils like jojoba or almond.",
        },
        {
          strong: "5) Cool Water: ",
          text: "Wash with lukewarm or cool water to prevent further dryness.",
        },
        {
          strong: "6) Exfoliate: ",
          text: "Use a gentle scalp scrub occasionally to remove flakes.",
        },
        {
          strong: "7) Avoid Harsh Products: ",
          text: "Stay away from alcohol or sulfate-laden products that can worsen dryness.",
        },
        {
          strong: "8) Deep Conditioning: ",
          text: "Use a hair mask regularly to restore moisture.",
        },
        {
          strong: "9) Stay Hydrated: ",
          text: "Drink plenty of water to maintain overall hydration.",
        },
        {
          strong: "10) Avoid Scratching: ",
          text: "Resist scratching to prevent irritation.",
        },
        {
          strong: "11) Healthy Diet: ",
          text: "Eat foods rich in omega-3 fatty acids for scalp health.",
        },
        {
          strong: "12) Manage Stress: ",
          text: "Practice stress-reducing activities like meditation.",
        },
        {
          strong: "13) Weather Protection: ",
          text: "Shield scalp from harsh elements with hats or scarves.",
        },
      ],
    },
    "Red and Irritated Scalp": {
      heading: "SCALP",
      title: "Red and Irritated Scalp",
      paragraphs: [
        {
          strong: "",
          text: "If you're experiencing a red and irritated scalp, there are several management tips you can try to alleviate the discomfort. However, it's essential to consult a healthcare professional or dermatologist for a proper diagnosis and personalized treatment plan. Here are some general management tips:",
        },
        {
          strong: "1) Keep Scalp Clean: ",
          text: "Use a gentle, sulfate-free shampoo and avoid harsh hair products.",
        },
        {
          strong: "2) Avoid Scratching: ",
          text: "Resisting scratching prevents further irritation.",
        },
        {
          strong: "3) Moisturize: ",
          text: "Apply soothing creams with aloe vera or chamomile to reduce inflammation.",
        },
        {
          strong: "4) Limit Heat and Styling: ",
          text: "Minimize use of heat tools and styling products.",
        },
        {
          strong: "5) Identify Triggers: ",
          text: "Discontinue products causing reactions.",
        },
        {
          strong: "6) Sun Protection: ",
          text: "Wear a hat or use scalp sunscreen.",
        },
        { strong: "7) Manage Stress: ", text: "Practice relaxation techniques." },
        {
          strong: "8) Use Cool Water: ",
          text: "Wash with cool water to avoid stripping natural oils.",
        },
        {
          strong: "9) Avoid Tight Hairstyles: ",
          text: "Reduce pressure on the scalp.",
        },
        {
          strong: "",
          text: "Remember, these tips are general suggestions and may not be suitable for everyone or for specific conditions.",
        },
      ],
    },



    "Dull Hair": {
      heading: "HAIR QUALITY",
      title: "Dull Hair",
      paragraphs: [
        {
          strong: "",
          text: "If you're dealing with dull hair, there are several management tips you can follow to improve its appearance and overall health. Here are some suggestions:",
        },
        {
          strong: "1) Healthy Diet: ",
          text: "Include a variety of fruits, vegetables, nuts, seeds, fish, and lean meats for essential nutrients.",
        },
        {
          strong: "2) Stay Hydrated: ",
          text: "Drink plenty of water daily to keep hair and scalp hydrated.",
        },
        {
          strong: "3) Gentle Hair Care: ",
          text: "Use sulfate-free, silicone-free shampoos and conditioners to maintain moisture.",
        },
        {
          strong: "4) Limit Heat Styling: ",
          text: "Minimize use of heat tools or use lowest heat settings.",
        },
        {
          strong: "5) Sun Protection: ",
          text: "Wear hats or use UV protection products to shield hair from sun damage.",
        },
        {
          strong: "6) Avoid Overwashing: ",
          text: "Wash hair every other day or as needed to prevent dryness.",
        },
        {
          strong: "7) Leave-In Conditioner: ",
          text: "Apply to damp hair for added moisture and protection.",
        },
        {
          strong: "8) Regular Trims: ",
          text: "Trim split ends for healthier-looking hair.",
        },
        {
          strong: "9) Deep Conditioning: ",
          text: "Use treatments or masks weekly for nourishment.",
        },
        {
          strong: "10) Avoid Tight Hairstyles: ",
          text: "Prevent damage and breakage by avoiding tight styles.",
        },
        {
          strong: "11) Limit Chemical Treatments: ",
          text: "Reduce frequency of harsh treatments like dyes and perms.",
        },
        {
          strong: "12) Use Hair Oils: ",
          text: "Apply natural oils like coconut or argan for shine and hydration.",
        },
      ],
    },
    "Frizzy Hair": {
      heading: "HAIR QUALITY",
      title: "Frizzy Hair",
      paragraphs: [
        {
          strong: "1) Moisturizing Products: ",
          text: "Use shampoos and conditioners designed for dry or frizzy hair to hydrate and reduce frizz.",
        },
        {
          strong: "2) Avoid Harsh Ingredients: ",
          text: "Stay away from sulfates and alcohol which strip natural oils, exacerbating frizz.",
        },
        {
          strong: "3) Wide-Tooth Comb: ",
          text: "Detangle gently with a wide-tooth comb while wet to prevent breakage and minimize frizz.",
        },
        {
          strong: "4) Pat Dry: ",
          text: "After washing, pat hair dry gently with a towel to prevent frizz and breakage.",
        },
        {
          strong: "5) Leave-In Conditioner: ",
          text: "Apply to damp hair for extra moisture and frizz protection, focusing on ends.",
        },
        {
          strong: "6) Limit Heat Styling: ",
          text: "Reduce use of heat tools to prevent damage and frizz; use a heat protectant if needed.",
        },
        {
          strong: "7) Air Dry: ",
          text: "Let hair air dry to reduce frizz and enhance natural texture.",
        },
        {
          strong: "8) Anti-Frizz Products: ",
          text: "Use creams, serums, or oils designed to combat frizz and smooth hair cuticles.",
        },
        {
          strong: "9) Regular Trims: ",
          text: "Prevent split ends with trims every 6 to 8 weeks.",
        },
        {
          strong: "10) Humidity Protection: ",
          text: "Use products or hairstyles that shield hair from humidity, like braids or updos.",
        },
        {
          strong: "11) Silk Pillowcase: ",
          text: "Sleep on silk or satin to minimize friction, reducing frizz and breakage.",
        },
        {
          strong: "12) Deep Conditioning: ",
          text: "Use treatments weekly for extra hydration and nourishment.",
        },
        {
          strong: "13) Limit Brushing: ",
          text: "Avoid over-brushing; use wide-tooth combs or detangling brushes as needed.",
        },
      ],
    },
    "Tangles Easily & Forms Knot": {
      heading: "HAIR QUALITY",
      title: "Tangles Easily & Forms Knot",
      paragraphs: [
        {
          strong: "",
          text: "Managing hair that tangles easily and forms knots can be challenging, but with the right techniques and products, you can minimize tangles and keep your hair more manageable. Here are some tips for managing tangled and knotted hair: ",
        },
        {
          strong: "1) Wide-Tooth Comb or Detangling Brush: ",
          text: "Gently remove tangles, starting from ends and working upwards.",
        },
        {
          strong: "2) Apply Conditioner Liberally; ",
          text: "Moisturize hair thoroughly, focusing on ends, to reduce tangles.",
        },
        {
          strong: "3) Detangle Wet Hair: ",
          text: "Do so after applying conditioner in the shower when hair is elastic and less prone to breakage.",
        },
        {
          strong: "4) Use Leave-In Conditioner or Detangling Spray: ",
          text: "Make combing easier and reduce tangles with leave-in products.",
        },
        {
          strong: "5) Avoid Overwashing: ",
          text: "Prevent dryness and tangles by washing hair every other day or as needed.",
        },
        {
          strong: "6) Protect Hair While Sleeping: ",
          text: "Use a loose braid or silk/satin pillowcase to minimize friction and knots.",
        },
        {
          strong: "7) Limit Heat Styling: ",
          text: "Heat tools can damage hair and increase tangling; use a heat protectant if necessary.",
        },
        {
          strong: "8) Trim Regularly: ",
          text: "Prevent split ends by trimming every 6 to 8 weeks.",
        },
        {
          strong: "9) Protect Hair During Activities: ",
          text: "Wear protective styles or swim caps during activities prone to tangling.",
        },
        {
          strong: "10) Avoid Harsh Chemical Treatments: ",
          text: "Minimize treatments like perms and bleach, which weaken hair.",
        },
        {
          strong: "11) Use Oils or Serums: ",
          text: "Add moisture and reduce friction with hair oils or serums on ends.",
        },
        {
          strong: "12) Be Gentle: ",
          text: "Handle wet hair delicately to avoid breakage; don't tug forcefully at knots.",
        },
        {
          strong: "",
          text: "Remember, patience is essential when dealing with tangled and knotted hair. Developing a consistent hair care routine with proper techniques and products can gradually improve the condition of your hair and reduce tangling. ",
        },
      ],
    },
    "Split Ends": {
      heading: "HAIR QUALITY",
      title: "Split Ends",
      paragraphs: [
        {
          strong: "",
          text: "Managing split ends in hair involves a combination of preventive measures and trimming techniques. While there's no way to repair split ends entirely, following these tips can help minimize their occurrence and improve the overall health of your hair: ",
        },
        {
          strong: "1) Regular Trims: ",
          text: "Trim hair every 6 to 8 weeks to remove split ends.",
        },
        {
          strong: "2) Sharp Scissors: ",
          text: "Use quality scissors to prevent further damage.",
        },
        {
          strong: "3) Reduce Heat Styling: ",
          text: "Minimize heat tools or use a protectant spray.",
        },
        {
          strong: "4) Avoid Harsh Chemicals: ",
          text: "Limit perms, relaxers, and bleaching.",
        },
        {
          strong: "5) Gentle Brushing: ",
          text: "Use wide-tooth combs and brush gently.",
        },
        {
          strong: "6) Leave-In Conditioner: ",
          text: "Keep hair moisturized to prevent splits.",
        },
        {
          strong: "7) Protective Wear: ",
          text: "Shield hair from sun, wind, and pollution.",
        },
        {
          strong: "8) Avoid Tight Styles: ",
          text: "Prevent stress on the hair shaft.",
        },
        {
          strong: "9) Deep Conditioning: ",
          text: "Hydrate hair weekly to prevent splits.",
        },
        {
          strong: "10) Use Protective Oils: ",
          text: "Apply natural oils to seal in moisture",
        },
        {
          strong: "11) Limit Chemical Treatments: ",
          text: "Reduce dye and harsh treatments. Remember, once split ends occur, trimming is the only solution. Regular care can help prevent them and keep hair healthy.",
        },
      ],
    },
    "Greasy Hair (Oily)": {
      heading: "HAIR QUALITY",
      title: "Greasy Hair (Oily)",
      paragraphs: [
        {
          strong: "",
          text: "Managing greasy or oily hair involves adopting a hair care routine that balances oil production on the scalp while keeping your hair clean and fresh. Here are some tips to manage greasy or oily hair: ",
        },
        {
          strong: "1) Clarifying Shampoo: ",
          text: "Use weekly to remove excess oil and buildup.",
        },
        {
          strong: "2) Regular Washing: ",
          text: "Keep hair clean, but avoid overwashing.",
        },
        {
          strong: "3) Choose Oil Control Products: ",
          text: "Opt for shampoos labeled for oily hair.",
        },
        {
          strong: "4) Avoid Heavy Products: ",
          text: "Steer clear of pomades and heavy serums.",
        },
        {
          strong: "5) Hands Off: ",
          text: "Minimize touching hair to reduce oil transfer.",
        },
        {
          strong: "6) Dry Shampoo: ",
          text: "Use sparingly between washes for a quick refresh.",
        },
        {
          strong: "7) Cool Water: ",
          text: "Wash with lukewarm water to prevent excess oil.",
        },
        {
          strong: "8) Limit Conditioner: ",
          text: "Apply mainly to ends to avoid over-conditioning.",
        },
        {
          strong: "9) Regular Brushing: ",
          text: "Distribute natural oils along the hair shaft.",
        },
        {
          strong: "10) Minimize Heat Styling: ",
          text: "Use heat protectant and low settings.",
        },
        {
          strong: "11) Hair Tie-Up: ",
          text: "Keep hair away from face to prevent oil transfer.",
        },
        {
          strong: "12) Healthy Diet: ",
          text: "Reduce greasy and fatty foods in your diet.",
        },
      ],
    },
    "Dry Hair": {
      heading: "HAIR QUALITY",
      title: "Dry Hair",
      paragraphs: [
        {
          strong: "",
          text: "Managing dry hair involves a combination of proper hair care practices and lifestyle changes to restore moisture and improve the overall health of your hair. Here are some tips to help manage dry hair: ",
        },
        {
          strong: "1) Moisturizing Products: ",
          text: "Opt for sulfate-free shampoo and conditioner designed for dry hair.",
        },
        {
          strong: "2) Limit Washing: ",
          text: "Avoid daily washing to retain natural oils; opt for every other day or a few times a week.",
        },
        {
          strong: "3) Use Lukewarm Water: ",
          text: "Prevent drying out by washing with lukewarm water.",
        },
        {
          strong: "4) Limit Heat Styling: ",
          text: "Minimize use of heat tools or apply heat protectant before styling.",
        },
        {
          strong: "5) Air Dry: ",
          text: "Allow hair to air dry to reduce heat damage and retain moisture.",
        },
        {
          strong: "6) Leave-In Conditioner: ",
          text: " Apply for added moisture, detangling, and frizz control.",
        },
        {
          strong: "7) Deep Conditioning: ",
          text: "Weekly treatments with nourishing ingredients provide intense hydration.",
        },
        {
          strong: "8) Sun Protection: ",
          text: "Wear a hat or use UV protection hair products to shield from sun damage.",
        },
        {
          strong: "9) Avoid Chemical Treatments: ",
          text: "Minimize or avoid treatments like dyes and perms that further dry hair.",
        },
        {
          strong: "10) Use Wide-Tooth Comb: ",
          text: "Minimize breakage by combing gently from ends to roots.",
        },
        {
          strong: "11) Regular Trims: ",
          text: "Prevent damage and split ends by trimming regularly.",
        },
        {
          strong: "12) Hydration and Diet: ",
          text: "Stay hydrated and maintain a balanced diet for overall hair health.",
        },
        {
          strong: "13) Hair Oils: ",
          text: "Lock in moisture and add shine with natural oils like coconut or argan. Consistency is key in managing dry hair. Stick to a healthy hair care routine for noticeable improvements over time.",
        },
      ],
    },
    "Brittle Hair": {
      heading: "HAIR QUALITY",
      title: "Brittle Hair",
      paragraphs: [
        {
          strong: "",
          text: "Managing brittle hair involves a combination of proper hair care practices and lifestyle changes to strengthen your hair and improve its overall health. Here are some tips to help manage brittle hair:",
        },
        {
          strong: "1) Gentle Products: ",
          text: "Opt for sulfate-free, moisturizing shampoos and conditioners tailored for brittle hair.",
        },
        {
          strong: "2) Limit Washing: ",
          text: "Reduce frequency to preserve natural oils; aim for every other day or a few times weekly.",
        },
        {
          strong: "3) Use Lukewarm Water: ",
          text: "Prevent stripping natural oils by washing with lukewarm water.",
        },
        {
          strong: "4) Target Conditioner: ",
          text: "Apply conditioner mainly to ends to avoid weighing down hair.",
        },
        {
          strong: "5) Leave-In Conditioner: ",
          text: "Add extra moisture and protection, especially for very brittle hair.",
        },
        {
          strong: "6) Deep Conditioning: ",
          text: "Use regular treatments with ingredients like keratin or coconut oil for hydration.",
        },
        {
          strong: "7) Limit Heat Styling: ",
          text: "Minimize use of heat tools; apply heat protectant and use lowest heat setting if necessary.",
        },
        {
          strong: "8) Protective Measures: ",
          text: "Shield hair from environmental damage with hats or protective hairstyles.",
        },
        {
          strong: "9) Avoid Tight Styles: ",
          text: "Prevent breakage and brittleness by avoiding tension-inducing hairstyles.",
        },
        {
          strong: "10) Regular Trims: ",
          text: "Trim routinely to remove split ends and prevent further damage.",
        },
        {
          strong: "11) Hydration and Nutrition: ",
          text: "Stay hydrated and maintain a balanced diet for hair health.",
        },
        {
          strong: "12) Hair Oils: ",
          text: "Seal in moisture and add shine with natural oils like coconut or argan.",
        },
        {
          strong: "13) Sleep Protection: ",
          text: "Minimize friction and breakage with satin or silk pillowcases or loose braids while sleeping. Consistency and patience are key in managing brittle hair. Stick to a healthy hair care routine for noticeable improvements over time.",
        },
      ],
    },
    Decreased: {
      heading: "HAIR DENSITY",
      title: "Decreased",
      paragraphs: [
        {
          strong: "",
          text: "Managing decreased hair density involves a combination of lifestyle changes, proper hair care, and, in some cases, medical intervention. Here are some tips to help manage decreased hair density: ",
        },
        {
          strong: "1) Seek Professional Advice: ",
          text: "Consult a dermatologist or healthcare provider to identify potential causes for decreased hair density, such as alopecia or hormonal imbalances.",
        },
        {
          strong: "2) Balanced Diet: ",
          text: "Ensure your diet includes essential nutrients like vitamins (A, C, D, E, B-complex), minerals (iron, zinc, selenium), and proteins to support healthy hair growth.",
        },
        {
          strong: "3) Stress Reduction: ",
          text: "Manage stress through practices like meditation or yoga to potentially improve hair density.",
        },
        {
          strong: "4) Avoid Traction Hairstyles: ",
          text: "Steer clear of hairstyles like tight braids or ponytails that can damage hair follicles and lead to hair loss.",
        },
        {
          strong: "5) Handle Hair with Care: ",
          text: "Be gentle when brushing, combing, or styling, particularly when hair is wet and more prone to breakage.",
        },
        {
          strong: "6) Limit Heat Styling: ",
          text: "Reduce use of heat tools like hair dryers and straighteners to prevent hair weakening and damage.",
        },
        {
          strong: "7) Use Gentle Products: ",
          text: "Opt for hair products without harsh chemicals or alcohol to avoid further hair and scalp damage.",
        },
        {
          strong: "8) Explore Hair Growth Treatments: ",
          text: "Consult a professional about over-the-counter or prescription treatments like minoxidil or finasteride to stimulate hair growth.",
        },
        {
          strong: "9) Scalp Massage: ",
          text: "Promote blood circulation and potential hair growth by gently massaging the scalp regularly.",
        },
        {
          strong: "10) Consider Laser Therapy: ",
          text: "Discuss low-level laser therapy (LLLT) options with a professional to stimulate hair growth.",
        },
        {
          strong: "11) Explore PRP Therapy: ",
          text: "Investigate Platelet-Rich Plasma (PRP) therapy under professional guidance for potential hair growth benefits.",
        },
        {
          strong: "12) Lifestyle Habits: ",
          text: "Avoid smoking and excessive alcohol consumption, which can negatively impact hair health and contribute to hair loss.",
        },
      ],
    },
    "Faded (Dull) Hair Color": {
      heading: "HAIR COLOR VIBRANCY",
      title: "Faded (Dull) Hair Color",
      paragraphs: [
        {
          strong: "",
          text: "If your hair color has faded or become dull, there are several management tips and tricks you can try to revive and maintain your color. Here are some suggestions: ",
        },
        {
          strong: "1) Color-Protecting Products: ",
          text: "Use shampoos and conditioners designed for color-treated hair to preserve color and prevent fading.",
        },
        {
          strong: "2) Wash Less Frequently: ",
          text: "Minimize washing to every other day or less to prevent stripping color and natural oils.",
        },
        {
          strong: "3) Cool Water Rinse: ",
          text: "Seal hair cuticles and enhance shine by rinsing with cool water during washing.",
        },
        {
          strong: "4) Limit Heat Styling: ",
          text: "Protect color from fading by using heat styling tools sparingly and with a heat protectant.",
        },
        {
          strong: "5) Sun Protection: ",
          text: "Shield hair from UV rays with hats or UV-protectant hair products during outdoor activities.",
        },
        {
          strong: "6) Chlorine and Saltwater: ",
          text: "Rinse hair thoroughly after swimming and consider leave-in conditioner to counteract chlorine and saltwater effects.",
        },
        {
          strong: "7) Color-Enhancing Products: ",
          text: "Maintain color intensity with shampoos, conditioners, and masks tailored to your hair color.",
        },
        {
          strong: "8) Gloss Treatments: ",
          text: "Enhance shine and vibrancy with salon gloss or glaze treatments.",
        },
        {
          strong: "9) Avoid Harsh Chemicals: ",
          text: "Minimize use of damaging chemicals like bleach and perms to prolong color life.",
        },
        {
          strong: "10) Deep Conditioning: ",
          text: "Keep hair hydrated and vibrant with regular deep conditioning treatments.",
        },
        {
          strong: "11) Alcohol-Free Products: ",
          text: "Opt for alcohol-free hair products to avoid drying out color-treated hair.",
        },
        {
          strong: "12) Regular Trims: ",
          text: "Trim hair regularly to prevent split ends and maintain overall hair health.",
        },
        {
          strong: "13) Color Refresh: ",
          text: "Consider returning to the salon for color touch-ups if your hair color has significantly faded.",
        },
        {
          strong: "",
          text: "Remember that hair color fading is a natural process, especially with vibrant or semi-permanent dyes. By following these management tips and maintaining a healthy hair care routine, you can help extend the life of your color and keep it looking fresh and vibrant for longer. ",
        },
      ],
    },
    "Lack of Moisture": {
      heading: "HAIR MOISTURE",
      title: "Lack of Moisture",
      paragraphs: [
        {
          strong: "",
          text: "Managing hair that lacks moisture involves a combination of proper hair care practices and lifestyle changes to hydrate and nourish your hair. Here are some tips to help manage hair with a lack of moisture:",
        },
        {
          strong: "1) Moisturizing Haircare: ",
          text: "Opt for sulfate-free, moisturizing shampoos and conditioners designed for dry or damaged hair to retain moisture.",
        },
        {
          strong: "2) Washing Frequency: ",
          text: "Limit hair washing to every other day or a few times a week to prevent stripping natural oils and dryness.",
        },
        {
          strong: "3) Water Temperature: ",
          text: "Wash hair with lukewarm water to avoid further drying out the hair and scalp.",
        },
        {
          strong: "4) Proper Conditioning: ",
          text: "Apply conditioner primarily to the ends of hair to prevent weighing it down, avoiding the scalp.",
        },
        {
          strong: "5) Leave-In Conditioner: ",
          text: "For extra moisture and protection, use leave-in conditioners on damp hair, especially for dry hair types.",
        },
        {
          strong: "6) Deep Conditioning: ",
          text: "Regularly use deep conditioning treatments or hair masks with nourishing ingredients like shea butter, coconut oil, or argan oil.",
        },
        {
          strong: "7) Limit Heat Styling: ",
          text: "Reduce heat styling tool usage to prevent dryness and damage.",
        },
        {
          strong: "8) Sun Protection: ",
          text: "Shield hair from sun damage with hats or UV-protectant hair products during outdoor activities.",
        },
        {
          strong: "9) Avoid Harsh Chemicals: ",
          text: "Minimize or avoid chemical treatments like dyes, bleach, and perms to prevent further drying out hair.",
        },
        {
          strong: "10) Hair Oils: ",
          text: "Apply natural oils like coconut oil, argan oil, or jojoba oil to hair ends for moisture locking and added shine.",
        },
        {
          strong: "11) Hydration and Nutrition: ",
          text: "Maintain hair health with adequate hydration and a balanced diet rich in vitamins and minerals.",
        },
        {
          strong: "12) Limit Alcohol and Caffeine: ",
          text: "Reduce alcohol and caffeine intake to prevent dehydration, which can affect hair moisture levels.",
        },
        {
          strong: "",
          text: "Remember that managing dry hair may take time and consistency. Be patient and continue to follow a healthy hair care routine, and you should see improvements in the moisture and overall health of your hair over time. ",
        },
      ],
    },
    "Excessive Breakage": {
      heading: "HAIR BREAKAGE",
      title: "Excessive Breakage",
      paragraphs: [
        {
          strong: "",
          text: "Experiencing excessive hair breakage is worrying, but addressing it promptly can prevent further damage. Here's how to handle it: ",
        },
        {
          strong: "1) Identify the Cause: ",
          text: "Determine what's causing the breakage, whether it's heat styling, chemical treatments, or other factors. Consult a dermatologist or trichologist for accurate diagnosis.",
        },
        {
          strong: "2) Reduce Heat Styling: ",
          text: "Minimize use of heat tools like dryers and straighteners, and always use a heat protectant.",
        },
        {
          strong: "3) Avoid Over-processing: ",
          text: "Give your hair a break from harsh chemical treatments to allow it to recover.",
        },
        {
          strong: "4) Use Gentle Products: ",
          text: "Opt for sulfate-free shampoos and conditioners that provide hydration and nourishment.",
        },
        {
          strong: "5) Deep Condition Regularly: ",
          text: "Treat your hair to deep conditioning masks with ingredients like keratin and coconut oil.",
        },
        {
          strong: "6) Trim Split Ends: ",
          text: "Regular trims every 6 to 8 weeks can prevent further breakage.",
        },
        {
          strong: "7) Avoid Tight Hairstyles: ",
          text: "Opt for looser styles to reduce tension on your hair.",
        },
        {
          strong: "8) Protect Hair at Night: ",
          text: "Sleep on satin or silk pillowcases to minimize friction.",
        },
        {
          strong: "9) Maintain a Balanced Diet: ",
          text: "Ensure you're getting essential nutrients for healthy hair growth.",
        },
        {
          strong: "10) Stay Hydrated: ",
          text: "Drink plenty of water to keep your hair hydrated.",
        },
        {
          strong: "11) Manage Stress: ",
          text: "Practice stress-reducing techniques to promote overall well-being.",
        },
      ],
    },
  };

  console.log("jafedojerofre", data?.hairScalp?.data?.scalp,content[data?.hairScalp?.data?.scalp]?.paragraphs?.length,data?.hairScalp?.data?.scalp?.length == 1);

  return (
    <div className="page-break-1">


      <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>

      <div
        className="head-managereport d-flex"
        style={{ justifyContent: "space-between" }}
      >
        <img src="/assets/img/logo.png" className="head-managereport-logo" />
        <div className="head-managereport-text1" style={{fontSize : "22px"}}>
          Hair & Scalp Treatment Recommendation
        </div>
        <div
          className="text-flex-line-center-veritcal"
          style={{ color: "rgba(84, 84, 84, 1)" }}
        >
          Smart Report
        </div>
      </div>
      <div className="d-flex" style={{ gap: "0.5%" }}>
        <div
          style={{
            width: "30%",
            background: "rgba(9, 150, 180, 1)",
            padding: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent : "center"
          }}
        >
          <img
            src="/assets/img/reports/management/page3/c7cc9b03af574a53b593ed98a32848b011318-xfpb-400h.png"
            style={{ height: "80%", width: "80%" }}
          />
        </div>
        <div
          style={{
            width: "69.5%",
            background: "rgba(9, 150, 180, 1)",
            padding: "15px",
          }}
          className="d-flex flex-column"
        >
          <div className="page3-sec-1-head">General Guidelines for</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="page3-sec-1-sub-head">Healthy Hair and Scalp</div>
          </div>
          <div className="d-flex flex-column" style={{ gap: "5px" }}>
            <div style={{ fontSize: "8px", color: "white" }}>
              <span className="page3-sec-1-text">
                1.Gentle Cleansing and Conditioning:
              </span>
              Wash hair every 2-3 days with a sulfate-free shampoo, and apply
              conditioner mainly to the lengths and ends to avoid scalp buildup.
            </div>
            <div style={{ fontSize: "8px", color: "white" }}>
              <span className="page3-sec-1-text">2.Nourish from Within:</span>{" "}
              Maintain a balanced diet rich in vitamins, minerals, and omega-3s,
              and stay hydrated to support hair and scalp health.
            </div>
            <div style={{ fontSize: "8px", color: "white" }}>
              <span className="page3-sec-1-text">
                3.Minimize Chemical and Heat Exposure::
              </span>
              Avoid harsh chemicals like sulfates and parabens, limit heat
              styling, and always use a heat protectant.
            </div>
            <div style={{ fontSize: "8px", color: "white" }}>
              <span className="page3-sec-1-text">4.Scalp Care:</span>Massage the
              scalp during shampooing to boost blood circulation, promoting
              healthy hair growth.
            </div>
            <div style={{ fontSize: "8px", color: "white" }}>
              <span className="page3-sec-1-text">5.Protect and Maintain:</span>
              Shield your hair and scalp from sun damage with hats or sunscreen,
              opt for loose hairstyles, and get trims every 6-8 weeks to keep
              hair healthy.
            </div>
          </div>
        </div>
      </div>




      {data?.hairScalp?.data?.scalp?.length == 1 ? data?.hairScalp?.data?.scalp?.slice(0,1)?.map((item) => {
        return (<>
                  {
            item != "Normal Scalp" ? 
            <div className="d-flex" style={{ gap: "0.5%", marginTop: "3px" }}>
            <div
              style={{
                width: "30%",
                background: "rgba(9, 150, 180, 1)",
                padding: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/img/reports/management/page3/image4ef437afc3ff436497a997fe97bc675e11319-802c-500w.png"
                style={{ height: "65%", width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "69.5%",
                background: "rgba(9, 150, 180, 1)",
                padding: "15px",
              }}
              className="d-flex flex-column"
            >
              <div className="page3-sec-1-head">General Guidelines for</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="page3-sec-1-sub-head">{content[item]?.title}</div>
              </div>
              <div style={{ fontSize: "8px", color: "white" }} className="mt-2">
                {content[item]?.paragraphs[0]?.text}
              </div>
              <div className="d-flex flex-column" style={{ gap: "5px" }}>
                {
                  content[item]?.paragraphs?.slice(1)?.map((e,itt) => {
                    return(
                      <div style={{ fontSize: "8px", color: "white" }}>
                      <span className="page3-sec-1-text">{e?.strong}</span>
                      {e?.text}
                    </div>
                    )

                  })
                }

                {/* <div style={{ fontSize: "8px", color: "white" }}>
                  <span className="page3-sec-1-text">2.Lukewarm Water Rinse:</span>{" "}
                  Wash hair with lukewarm water to avoid stimulating extra oil
                  production.
                </div>
                <div style={{ fontSize: "8px", color: "white" }}>
                  <span className="page3-sec-1-text">
                    3.Weekly Deep Scalp Cleansing:
                  </span>{" "}
                  Use a clarifying shampoo once a week and focus on massaging it
                  into the scalp for thorough cleansing and buildup removal.
                </div>
                <div style={{ fontSize: "8px", color: "white" }}>
                  <span className="page3-sec-1-text">
                    4.Careful Conditioning and Minimal Handling:
                  </span>{" "}
                  Apply conditioner only to hair lengths and ends, and minimize
                  brushing and touching to avoid spreading scalp oils.
                </div>
                <div style={{ fontSize: "8px", color: "white" }}>
                  <span className="page3-sec-1-text">
                    5.Use Lightweight Products:
                  </span>
                  Select oil-free and non-comedogenic hair products to prevent
                  weighing down the hair.
                </div> */}
              </div>
            </div>
          </div> : <></>
          }
        </>

        );
      }) : data?.hairScalp?.data?.scalp ? <>
      {
data?.hairScalp?.data?.scalp != "Normal Scalp" ? 
<div className="d-flex" style={{ gap: "0.5%", marginTop: "3px" }}>
<div
  style={{
    width: "30%",
    background: "rgba(9, 150, 180, 1)",
    padding: "15px",
    display: "flex",
    alignItems: "center",
  }}
>
  <img
    src="/assets/img/reports/management/page3/image4ef437afc3ff436497a997fe97bc675e11319-802c-500w.png"
    style={{ height: "65%", width: "100%" }}
  />
</div>
<div
  style={{
    width: "69.5%",
    background: "rgba(9, 150, 180, 1)",
    padding: "15px",
  }}
  className="d-flex flex-column"
>
  <div className="page3-sec-1-head">General Guidelines for</div>
  <div style={{ display: "flex", justifyContent: "center" }}>
    <div className="page3-sec-1-sub-head">{content[data?.hairScalp?.data?.scalp]?.title}</div>
  </div>
  <div style={{ fontSize: "8px", color: "white" }} className="mt-2">
    {content[data?.hairScalp?.data?.scalp]?.paragraphs[0]?.text}
  </div>
  <div className="d-flex flex-column" style={{ gap: "5px" }}>
    {
      content[data?.hairScalp?.data?.scalp]?.paragraphs?.length > 0 && content[data?.hairScalp?.data?.scalp]?.paragraphs?.slice(1)?.map((e,itt) => {
        return(
          <div style={{ fontSize: "8px", color: "white" }}>
          <span className="page3-sec-1-text">{e?.strong}</span>
          {e?.text}
        </div>
        )

      })
    }

    {/* <div style={{ fontSize: "8px", color: "white" }}>
      <span className="page3-sec-1-text">2.Lukewarm Water Rinse:</span>{" "}
      Wash hair with lukewarm water to avoid stimulating extra oil
      production.
    </div>
    <div style={{ fontSize: "8px", color: "white" }}>
      <span className="page3-sec-1-text">
        3.Weekly Deep Scalp Cleansing:
      </span>{" "}
      Use a clarifying shampoo once a week and focus on massaging it
      into the scalp for thorough cleansing and buildup removal.
    </div>
    <div style={{ fontSize: "8px", color: "white" }}>
      <span className="page3-sec-1-text">
        4.Careful Conditioning and Minimal Handling:
      </span>{" "}
      Apply conditioner only to hair lengths and ends, and minimize
      brushing and touching to avoid spreading scalp oils.
    </div>
    <div style={{ fontSize: "8px", color: "white" }}>
      <span className="page3-sec-1-text">
        5.Use Lightweight Products:
      </span>
      Select oil-free and non-comedogenic hair products to prevent
      weighing down the hair.
    </div> */}
  </div> 
</div>
</div> : <></>
}
</> :<></>}


{ data?.hairScalp?.data?.hairQuality?.length==1 ?  data?.hairScalp?.data?.hairQuality?.slice(0,1)?.map((item) => {
        return (<>
        {
            item != "Good" ?       <div className="d-flex" style={{ gap: "0.5%", marginTop: "3px" }}>
            <div
              style={{
                width: "30%",
                background: "rgba(9, 150, 180, 1)",
                padding: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/img/reports/management/page3/e9144270c9ff437e90fdfe8eeec7a3be11320-yddj-500h.png"
                style={{ height: "65%", width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "69.5%",
                background: "rgba(9, 150, 180, 1)",
                padding: "15px",
              }}
              className="d-flex flex-column"
            >
              <div className="page3-sec-1-head">General Guidelines for</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="page3-sec-1-sub-head">{content[item]?.title}</div>
              </div>
              <div style={{ fontSize: "8px", color: "white" }} className="mt-2">
              {content[item]?.paragraphs[0]?.text}
              </div>
              <div className="d-flex flex-column" style={{ gap: "5px" }}>

              {
                  content[item]?.paragraphs?.slice(1)?.map((e,itt) => {

                    return(
                      <div style={{ fontSize: "8px", color: "white" }}>
                      <span className="page3-sec-1-text">{e?.strong}:</span>{e?.text}
                    </div>
                    )
                  })
                }

  
              </div>
            </div>
          </div> : <></>}
        </>)
      }) : data?.hairScalp?.data?.hairQuality ? <>
      {
          data?.hairScalp?.data?.hairQuality != "Good" ?       <div className="d-flex" style={{ gap: "0.5%", marginTop: "3px" }}>
          <div
            style={{
              width: "30%",
              background: "rgba(9, 150, 180, 1)",
              padding: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/img/reports/management/page3/e9144270c9ff437e90fdfe8eeec7a3be11320-yddj-500h.png"
              style={{ height: "65%", width: "100%" }}
            />
          </div>
          <div
            style={{
              width: "69.5%",
              background: "rgba(9, 150, 180, 1)",
              padding: "15px",
            }}
            className="d-flex flex-column"
          >
            <div className="page3-sec-1-head">General Guidelines for</div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="page3-sec-1-sub-head">{content[data?.hairScalp?.data?.hairQuality]?.title}</div>
            </div>
            <div style={{ fontSize: "8px", color: "white" }} className="mt-2">
            {content[data?.hairScalp?.data?.hairQuality]?.paragraphs[0]?.text}
            </div>
            <div className="d-flex flex-column" style={{ gap: "5px" }}>

            {
                content[data?.hairScalp?.data?.hairQuality]?.paragraphs?.slice(1)?.map((e,itt) => {

                  return(
                    <div style={{ fontSize: "8px", color: "white" }}>
                    <span className="page3-sec-1-text">{e?.strong}:</span>{e?.text}
                  </div>
                  )
                })
              }


            </div>
          </div>
        </div> : <></>}
      </> : <></>}

<div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)",padding : "10px 10px"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>
      </div>


      <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>
      <div
        className="head-managereport d-flex"
        style={{ justifyContent: "space-between" }}
      >
        <img src="/assets/img/logo.png" className="head-managereport-logo" />
        <div className="head-managereport-text1" style={{fontSize : "22px"}}>
          Hair & Scalp Treatment Recommendation
        </div>
        <div
          className="text-flex-line-center-veritcal"
          style={{ color: "rgba(84, 84, 84, 1)" }}
        >
          Smart Report
        </div>
      </div>
      <div
        className="d-flex"
        style={{ padding: "30px", gap: "2%",marginTop : "10px" }}
      >
        <div
          className="page3-sec-4-main d-flex flex-column"
          style={{
            width: "49%",
            padding: "10px 10px 20px 10px",
            position: "relative",
          }}
        >
          <div className="d-flex" style={{ height: "10%" }}>
            <div style={{ width: "40%" }}>
              <img
                src="/assets/img/reports/management/page3/image036c30a210eb409fbc73cc0c7f063ed91316-esg6-200h.png"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "60%",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="d-flex flex-column"
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Guidelines for
              </div>
              <div className="d-flex">
                <div
                  className="page3-sec-1-sub-head"
                  style={{ padding: "6px 25px" }}
                >
                  Good Hair Density
                </div>
              </div>
            </div>
          </div>

          <div
            className="page3-sec-4-sub mt-2 d-flex flex-column"
            style={{ height: "90%", fontSize: "10px", lineHeight: "25px" }}
          >
            <div>
              Managing hair density involves a combination of lifestyle changes,
              proper hair care, and, in some cases, medical intervention. Here
              are some tips to help manage hair density:
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                1. Avoid Traction Hairstyles:
              </span>{" "}
              Steer clear of tight hairstyles like braids or ponytails that can
              damage hair follicles and lead to hair loss.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>2. Handle Hair Gently:</span>{" "}
              Be cautious when brushing, combing, or styling, especially when
              hair is wet, to prevent breakage.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                3. Use Gentle Hair Products:
              </span>{" "}
              Choose hair care products that are free from harsh chemicals and
              alcohol to protect both hair and scalp health.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                4. Incorporate Scalp Massages:
              </span>{" "}
              Regularly massage the scalp to promote blood circulation, which
              can support hair growth.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                5. Explore Advanced Therapies:
              </span>{" "}
              Discuss options like Platelet-Rich Plasma (PRP) therapy or
              low-level laser therapy with a professional for potential hair
              growth benefits.
            </div>
          </div>
          <div className="page3-sec-4-last"></div>
        </div>

        <div
          className="page3-sec-4-main d-flex flex-column"
          style={{
            width: "49%",
            padding: "10px 10px 20px 10px",
            position: "relative",
          }}
        >
          <div className="d-flex" style={{ height: "10%" }}>
            <div style={{ width: "40%" }}>
              <img
                src="/assets/img/reports/management/page3/af66c2fc84fb4baea3882934afc636091317-iul-300w.png"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "60%",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="d-flex flex-column"
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Guidelines for
              </div>
              <div className="d-flex">
                <div
                  className="page3-sec-1-sub-head"
                  style={{ padding: "6px 25px" }}
                >
                  Good Hair Color
                </div>
              </div>
            </div>
          </div>

          <div
            className="page3-sec-4-sub mt-2 d-flex flex-column"
            style={{ height: "90%", fontSize: "10px", lineHeight: "25px" }}
          >
            <div>
              There are several management tips and tricks you can try to revive
              and maintain your hair color. Here are some suggestions:
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                1.Use Color-Protecting Products:
              </span>{" "}
              Choose shampoos and conditioners specifically designed for
              color-treated hair to preserve color vibrancy and prevent fading.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                2.Wash Less and Rinse Cool:
              </span>{" "}
              Limit hair washing to every other day or less to preserve color
              and natural oils, and use cool water for the final rinse to seal
              cuticles and enhance shine.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                3.Protect Hair from the Sun:
              </span>
              Wear hats or use UV-protectant hair products when spending time
              outdoors to shield your hair from harmful UV rays.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>4.Rinse After Swimming:</span>{" "}
              Thoroughly rinse hair after exposure to chlorine or saltwater, and
              apply a leave-in conditioner to protect hair from drying out.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                5.Enhance and Maintain Hair Color:{" "}
              </span>
              Use color-enhancing products like shampoos, conditioners, and
              masks, along with regular deep conditioning and gloss treatments
              to keep hair vibrant, hydrated, and shiny.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                6.Avoid Harsh Chemicals and Alcohol-Based Products:{" "}
              </span>
              Minimize exposure to damaging chemicals like bleach and perms, and
              opt for alcohol-free products to maintain moisture in
              color-treated hair.
            </div>
          </div>
          <div className="page3-sec-4-last"></div>
        </div>
      </div>


      <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)",marginTop : "3%"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>
      </div>


      <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>

      <div
        className="head-managereport d-flex"
        style={{ justifyContent: "space-between" }}
      >
        <img src="/assets/img/logo.png" className="head-managereport-logo" />
        <div className="head-managereport-text1" style={{fontSize : "22px"}}>
          Hair & Scalp Treatment Recommendation
        </div>
        <div
          className="text-flex-line-center-veritcal"
          style={{ color: "rgba(84, 84, 84, 1)" }}
        >
          Smart Report
        </div>
      </div>

      <div className="d-flex" style={{ padding: "30px", gap: "2%" }}>
        <div
          className="page3-sec-4-main d-flex flex-column"
          style={{
            width: "49%",
            padding: "10px 10px 20px 10px",
            position: "relative",
            height:"800px"
          }}
        >
          <div className="d-flex" style={{ height: "10%" }}>
            <div style={{ width: "40%" }}>
              <img
                src="/assets/img/reports/management/page3/a75c4e3480d5497584fceb351389672511314-n0hi-200h.png"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "60%",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="d-flex flex-column"
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Guidelines for
              </div>
              <div className="d-flex">
                <div
                  className="page3-sec-1-sub-head"
                  style={{ padding: "6px 25px" }}
                >
                  Good Moisture and Hydration of Hair
                </div>
              </div>
            </div>
          </div>

          <div
            className="page3-sec-4-sub mt-2 d-flex flex-column"
            style={{ height: "90%", fontSize: "10px", lineHeight: "25px" }}
          >
            <div>
              Managing hair that lacks moisture involves a combination of proper
              hair care practices and lifestyle changes to hydrate and nourish
              your hair. Here are some tips to help manage hair with a lack of
              moisture:
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                1.Use Moisturizing Haircare Products:
              </span>{" "}
              Choose sulfate-free shampoos and conditioners designed for dry or
              damaged hair to help retain moisture and reduce dryness.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                2.Wash Less with Lukewarm Water:
              </span>{" "}
              Limit hair washing to a few times a week and use lukewarm water to
              preserve natural oils and prevent dryness.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                3.Condition Strategically:
              </span>{" "}
              Focus regular conditioner on the ends to hydrate without weighing
              down hair, use leave-in conditioner on damp hair for extra
              moisture, and apply deep conditioning treatments with nourishing
              ingredients like shea butter or argan oil to restore hydration.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>4.Reduce Heat Styling:</span>{" "}
              Limit the use of heat tools to prevent further dryness and hair
              damage, and always use a heat protectant.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                5.Protect Hair from the Sun:
              </span>{" "}
              Shield hair from sun exposure with hats or UV-protectant hair
              products during outdoor activities.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                6.Avoid Harsh Chemicals and Nourish Hair:
              </span>{" "}
              Limit chemical treatments like dyes and bleach to prevent dryness.
              Instead, use natural oils (coconut, argan, jojoba) for moisture,
              and maintain a balanced diet to support hair health
            </div>
          </div>
          <div className="page3-sec-4-last"></div>
        </div>

        <div
          className="page3-sec-4-main d-flex flex-column"
          style={{
            width: "49%",
            padding: "10px 10px 20px 10px",
            position: "relative",
                        height:"800px"
          }}
        >
          <div className="d-flex" style={{ height: "10%" }}>
            <div style={{ width: "40%" }}>
              <img
                src="/assets/img/reports/management/page3/d380146ee98e4ce28d371ad733ff3dc71315-a3m-200h.png"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "60%",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="d-flex flex-column"
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Guidelines for to Avoid
              </div>
              <div className="d-flex">
                <div
                  className="page3-sec-1-sub-head"
                  style={{ padding: "6px 25px" }}
                >
                  Hair breakage
                </div>
              </div>
            </div>
          </div>

          <div
            className="page3-sec-4-sub mt-2 d-flex flex-column"
            style={{ height: "90%", fontSize: "10px", lineHeight: "25px" }}
          >
            <div>
              Experiencing excessive hair breakage is worrying, but addressing
              it promptly can prevent further damage. Here's how to handle it:
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                1.Reduce Heat and Chemical Damage:
              </span>{" "}
              Limit heat styling and always use a heat protectant, while giving
              your hair breaks from chemical treatments to prevent damage and
              promote recovery.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                2.Use Gentle, Hydrating Products:
              </span>{" "}
              Opt for sulfate-free shampoos and conditioners that provide
              nourishment and hydration to fragile hair.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                3.Incorporate Regular Deep Conditioning:
              </span>
              Use deep conditioning masks with ingredients like keratin or
              coconut oil weekly to strengthen and moisturize hair.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                4.Trim Split Ends Regularly:{" "}
              </span>
              Get trims every 6 to 8 weeks to remove split ends and prevent
              breakage from spreading.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                5.Avoid Tight Hairstyles:{" "}
              </span>
              Choose looser styles to reduce tension and stress on the hair,
              preventing further breakage.
            </div>
            <div>
              <span style={{ fontWeight: "600" }}>
                6.Protect Hair While Sleeping:{" "}
              </span>
              Sleep on satin or silk pillowcases to minimize friction and reduce
              the risk of breakage.
            </div>
          </div>
          <div className="page3-sec-4-last"></div>
        </div>
      </div>

      <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)",marginTop : "5%"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>
        </div>






    </div>
    //   <div className='mt-5'>
    //           <div
    //   class="management-report-page3-hair-scalp-treatment-recommendat-container"
    // >
    //   <div class="management-report-page3-hair-scalp-treatment-recommendat-rectangle34624510">
    //     <div class="management-report-page3-hair-scalp-treatment-recommendat-rectangle34624522">
    //       <img src="/assets/img/reports/management/page3/hairsn111280-f6t8-200h.png" alt=""class="management-report-page3-hair-scalp-treatment-recommendat-hairsn11"/>
    //     </div>
    //     <div class="management-report-page3-hair-scalp-treatment-recommendat-group1707485987">
    //       <span class="management-report-page3-hair-scalp-treatment-recommendat-text121"> Hair &amp; Scalp Treatment Recommendation </span>
    //     </div>
    //     <div class="header-txt"><span class="management-report-page3-hair-scalp-treatment-recommendat-text100">Smart Report</span></div>
    //   </div>
    //   <div class="guide-sec">
    //     <div class="guide-box1">
    //       <div class="image-guide1">
    //         <img
    //         src="/assets/img/reports/management/page3/c7cc9b03af574a53b593ed98a32848b011318-xfpb-400h.png" alt="" class="management-report-page3-hair-scalp-treatment-recommendat-c7cc9b03af574a53b593ed98a32848b01"/>
    //       </div>
    //       <div class="border-box1"></div>
    //       <div class="guide-text">
    //         <div class="text-guide">General Guidelines for</div>
    //         <div class="management-report-page3-hair-scalp-treatment-recommendat-frame2147224094" style={{marginTop: "30px"}}>
    //           <span class="management-report-page3-hair-scalp-treatment-recommendat-text118">Healthy Hair and Scalp</span>
    //        </div>
    //         <div class="sub-content1">
    //           <span
    //        class="management-report-page3-hair-scalp-treatment-recommendat-text101"
    //      >
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text102"
    //        >
    //          1.Gentle Cleansing and Conditioning
    //        </span>
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text103"
    //        >
    //          : Wash hair every 2-3 days with a sulfate-free shampoo, and apply
    //          conditioner mainly to the lengths and ends to avoid scalp buildup.
    //        </span>
    //        <br />
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text105"
    //        >
    //          2.Nourish from Within
    //        </span>
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text106"
    //        >
    //          : Maintain a balanced diet rich in vitamins, minerals, and
    //          omega-3s, and stay hydrated to support hair and scalp health.
    //        </span>
    //        <br />
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text108"
    //        >
    //          3.Minimize Chemical and Heat Exposure
    //        </span>
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text109"
    //        >
    //          : Avoid harsh chemicals like sulfates and parabens, limit heat
    //          styling, and always use a heat protectant.
    //        </span>
    //        <br />
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text111"
    //        >
    //          4.Scalp Care
    //        </span>
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text112"
    //        >
    //          : Massage the scalp during shampooing to boost blood circulation,
    //          promoting healthy hair growth.
    //        </span>
    //        <br />
    //        <span
    //          class="management-report-page3-hair-scalp-treatment-recommendat-text114"
    //        >
    //          5.Protect and Maintain
    //        </span>
    //        <span>
    //          : Shield your hair and scalp from sun damage with hats or
    //          sunscreen, opt for loose hairstyles, and get trims every 6-8 weeks
    //          to keep hair healthy.
    //        </span>
    //        <br />
    //      </span>
    //         </div>
    //       </div>
    //     </div>
    //     <div class="guide-box2">
    //       <div class="image-guide1">
    //         <img
    //         src="/assets/img/reports/management/page3/image4ef437afc3ff436497a997fe97bc675e11319-802c-500w.png" alt=""
    //         class="management-report-page3-hair-scalp-treatment-recommendat-c7cc9b03af574a53b593ed98a32848b01"/>
    //       </div>
    //       <div class="border-box1"></div>
    //       <div class="guide-text">
    //         <span class="management-report-page3-hair-scalp-treatment-recommendat-text122">
    //           General Guidelines for
    //         </span>
    //         <div class="management-report-page3-hair-scalp-treatment-recommendat-frame2147224095">
    //          <span class="management-report-page3-hair-scalp-treatment-recommendat-text119">
    //             Oily Scalp
    //          </span>
    //         </div>
    //         <div class="sub-content1">
    //           <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text124"
    //         >
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text125"
    //           >
    //             Managing an oily scalp can be achieved through a combination of
    //             proper hair care, lifestyle adjustments, and the use of suitable
    //             hair products. Here are some tips to effectively manage an oily
    //             scalp:
    //           </span>
    //           <br />
    //           <br />
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text128"
    //           >
    //             1.Frequent Shampooing
    //           </span>
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text129"
    //           >
    //             : Use a gentle shampoo formulated for oily hair to reduce excess
    //             scalp oil.
    //           </span>
    //           <br />
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text131"
    //           >
    //             2.Lukewarm Water Rinse
    //           </span>
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text132"
    //           >
    //             : Wash hair with lukewarm water to avoid stimulating extra oil
    //             production.
    //           </span>
    //           <br />
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text134"
    //           >
    //             3.Weekly Deep Scalp Cleansing
    //           </span>
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text135"
    //           >
    //             : Use a clarifying shampoo once a week and focus on massaging it
    //             into the scalp for thorough cleansing and buildup removal.
    //           </span>
    //           <br />
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text137"
    //           >
    //             4.Careful Conditioning and Minimal Handling
    //           </span>
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text138"
    //           >
    //             : Apply conditioner only to hair lengths and ends, and minimize
    //             brushing and touching to avoid spreading scalp oils.
    //           </span>
    //           <br />
    //           <span
    //             class="management-report-page3-hair-scalp-treatment-recommendat-text140"
    //           >
    //             5.Use Lightweight Products
    //           </span>
    //           <span>
    //             : Select oil-free and non-comedogenic hair products to prevent
    //             weighing down the hair.
    //           </span>
    //           <br />
    //         </span>
    //         </div>
    //       </div>
    //     </div>
    //     <div class="guide-box3">
    //       <div class="image-guide1">
    //         <img src="/assets/img/reports/management/page3/e9144270c9ff437e90fdfe8eeec7a3be11320-yddj-500h.png" alt=""class="management-report-page3-hair-scalp-treatment-recommendat-e9144270c9ff437e90fdfe8eeec7a3be1"/>
    //       </div>
    //       <div class="border-box"></div>
    //       <div class="guide-text">
    //         <span class="management-report-page3-hair-scalp-treatment-recommendat-text123">
    //           General Guidelines for
    //         </span>
    //         <div class="management-report-page3-hair-scalp-treatment-recommendat-frame2147224096">
    //          <span class="management-report-page3-hair-scalp-treatment-recommendat-text120">
    //             Dull Hair Quality
    //           </span>
    //        </div>
    //        <div class="sub-content3">
    //         <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text143"
    //       >
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text144"
    //         >
    //           If you're dealing with dull hair, there are several management
    //           tips you can follow to improve its appearance and overall health.
    //           Here are some suggestions:
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text146"
    //         >
    //           1.Sun Protection
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text147"
    //         >
    //           : Wear hats or use hair products with UV protection to shield hair
    //           from sun damage.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text149"
    //         >
    //           2.Avoid Overwashing:
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text150"
    //         >
    //           Wash hair every other day or as needed to prevent dryness and
    //           maintain natural oils.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text152"
    //         >
    //           3.Apply Leave-In Conditioner
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text153"
    //         >
    //           : Use leave-in conditioner on damp hair for added moisture,
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text155"
    //         >
    //           protection, and shine.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text157"
    //         >
    //           4.Regular Trims
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text158"
    //         >
    //           : Get trims every 6-8 weeks to remove split ends and promote
    //           healthier-looking hair.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text160"
    //         >
    //           5.Deep Conditioning and Hair Oils
    //         </span>
    //         <span>
    //           : Use weekly hair masks for nourishment and apply natural oils
    //           like coconut or argan for extra hydration and shine.
    //         </span>
    //         <br />
    //       </span>
    //        </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div class="last-sec">
    //     <div class="last-box1">
    //        <div class="last-flex1">
    //           <div class="last-fleximg">
    //             <img src="/assets/img/reports/management/page3/image036c30a210eb409fbc73cc0c7f063ed91316-esg6-200h.png" alt=""
    //             class="management-report-page3-hair-scalp-treatment-recommendat-image036c30a210eb409fbc73cc0c7f063ed9"/>
    //           </div>
    //          <div class="last-flextxt">
    //            <span class="management-report-page3-hair-scalp-treatment-recommendat-text163">
    //               Guidelines for
    //            </span>
    //            <div
    //            class="management-report-page3-hair-scalp-treatment-recommendat-frame2147224097"
    //          >
    //            <span
    //              class="management-report-page3-hair-scalp-treatment-recommendat-text183"
    //            >
    //              Good Hair Density
    //            </span>
    //          </div>
    //          </div>
    //        </div>
    //        <div class="lastsec-txt">
    //         <span
    //       class="management-report-page3-hair-scalp-treatment-recommendat-text164"
    //     >
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text165"
    //       >
    //         Managing hair density involves a combination of lifestyle changes,
    //         proper hair care, and, in some cases, medical intervention. Here
    //         are some tips to help manage hair density:
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text167"
    //       >
    //         1. Avoid Traction Hairstyles
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text168"
    //       >
    //         : Steer clear of tight hairstyles like braids or ponytails that
    //         can damage hair follicles and lead to hair loss.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text170"
    //       >
    //         2. Handle Hair Gently
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text171"
    //       >
    //         : Be cautious when brushing, combing, or styling, especially when
    //         hair is wet, to prevent breakage.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text173"
    //       >
    //         3. Use Gentle Hair Products
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text174"
    //       >
    //         : Choose hair care products that are free from harsh chemicals and
    //         alcohol to protect both hair and scalp health.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text176"
    //       >
    //         4. Incorporate Scalp Massages
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text177"
    //       >
    //         : Regularly massage the scalp to promote blood circulation, which
    //         can support hair growth.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text179"
    //       >
    //         5. Explore Advanced Therapies
    //       </span>
    //       <span>
    //         : Discuss options like Platelet-Rich Plasma (PRP) therapy or
    //         low-level laser therapy with a professional for potential hair
    //         growth benefits.
    //       </span>
    //        </span>
    //        </div>
    //        <div class="last-border"></div>
    //     </div>
    //     <div class="last-box2">
    //       <div class="last-flex1">
    //          <div class="last-fleximg">
    //           <img
    //           src="/assets/img/reports/management/page3/af66c2fc84fb4baea3882934afc636091317-iul-300w.png"
    //           alt=""
    //           class="management-report-page3-hair-scalp-treatment-recommendat-af66c2fc84fb4baea3882934afc63609"/>

    //          </div>
    //         <div class="last-flextxt">
    //           <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text181"
    //         >
    //           Guidelines for
    //         </span>
    //         <div
    //         class="management-report-page3-hair-scalp-treatment-recommendat-frame2147224098"
    //       >
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text184"
    //         >
    //           Good Hair Color
    //         </span>
    //       </div>
    //         </div>
    //       </div>
    //       <div class="lastsec-txt2">
    //         <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text187"
    //       >
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text188"
    //         >
    //           There are several management tips and tricks you can try to revive
    //           and maintain your hair color. Here are some suggestions:
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text190"
    //         >
    //           1.Use Color-Protecting Products
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text191"
    //         >
    //           : Choose shampoos and conditioners specifically designed for
    //           color-treated hair to preserve color vibrancy and prevent fading.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text193"
    //         >
    //           2.Wash Less and Rinse Cool
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text194"
    //         >
    //           : Limit hair washing to every other day or less to preserve color
    //           and natural oils, and use cool water for the final rinse to seal
    //           cuticles and enhance shine.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text196"
    //         >
    //           3.Protect Hair from the Sun
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text197"
    //         >
    //           : Wear hats or use UV-protectant hair products when spending time
    //           outdoors to shield your hair from harmful UV rays.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text199"
    //         >
    //           4.Rinse After Swimming
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text200"
    //         >
    //           : Thoroughly rinse hair after exposure to chlorine or saltwater,
    //           and apply a leave-in conditioner to protect hair from drying out.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text202"
    //         >
    //           5.Enhance and Maintain Hair Color
    //         </span>
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text203"
    //         >
    //           : Use color-enhancing products like shampoos, conditioners, and
    //           masks, along with regular deep conditioning and gloss treatments
    //           to keep hair vibrant, hydrated, and shiny.
    //         </span>
    //         <br />
    //         <span
    //           class="management-report-page3-hair-scalp-treatment-recommendat-text205"
    //         >
    //           6.Avoid Harsh Chemicals and Alcohol-Based Products
    //         </span>
    //         <span>
    //           : Minimize exposure to damaging chemicals like bleach and perms,
    //           and opt for alcohol-free products to maintain moisture in
    //           color-treated hair.
    //         </span>
    //         <br />
    //       </span>
    //       </div>
    //       <div class="last-border2"></div>
    //    </div>
    //    <div class="last-box3">
    //     <div class="last-flex2">
    //        <div class="last-fleximg">
    //         <img
    //       src="/assets/img/reports/management/page3/a75c4e3480d5497584fceb351389672511314-n0hi-200h.png"
    //       alt="a75c4e3480d5497584fceb351389672511314"
    //       class="management-report-page3-hair-scalp-treatment-recommendat-a75c4e3480d5497584fceb35138967251"/>
    //        </div>
    //       <div class="last-flextxt">
    //         <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text208"
    //       >
    //         Guidelines for
    //       </span>
    //       <div
    //       class="management-report-page3-hair-scalp-treatment-recommendat-frame2147224101"
    //     >
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text186"
    //       >
    //         Good Moisture and Hydration of Hair
    //       </span>
    //     </div>
    //     </div>
    //    </div>
    //    <div class="lastsec-txt3">
    //       <span
    //       class="management-report-page3-hair-scalp-treatment-recommendat-text187"
    //     >
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text188"
    //       >
    //         There are several management tips and tricks you can try to revive
    //         and maintain your hair color. Here are some suggestions:
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text190"
    //       >
    //         1.Use Color-Protecting Products
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text191"
    //       >
    //         : Choose shampoos and conditioners specifically designed for
    //         color-treated hair to preserve color vibrancy and prevent fading.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text193"
    //       >
    //         2.Wash Less and Rinse Cool
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text194"
    //       >
    //         : Limit hair washing to every other day or less to preserve color
    //         and natural oils, and use cool water for the final rinse to seal
    //         cuticles and enhance shine.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text196"
    //       >
    //         3.Protect Hair from the Sun
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text197"
    //       >
    //         : Wear hats or use UV-protectant hair products when spending time
    //         outdoors to shield your hair from harmful UV rays.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text199"
    //       >
    //         4.Rinse After Swimming
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text200"
    //       >
    //         : Thoroughly rinse hair after exposure to chlorine or saltwater,
    //         and apply a leave-in conditioner to protect hair from drying out.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text202"
    //       >
    //         5.Enhance and Maintain Hair Color
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text203"
    //       >
    //         : Use color-enhancing products like shampoos, conditioners, and
    //         masks, along with regular deep conditioning and gloss treatments
    //         to keep hair vibrant, hydrated, and shiny.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text205"
    //       >
    //         6.Avoid Harsh Chemicals and Alcohol-Based Products
    //       </span>
    //       <span>
    //         : Minimize exposure to damaging chemicals like bleach and perms,
    //         and opt for alcohol-free products to maintain moisture in
    //         color-treated hair.
    //       </span>
    //       <br />
    //     </span>
    //     </div>
    //     <div class="last-border3"></div>
    //     </div>

    //     <div class="last-box4">
    //       <div class="last-flex2">
    //          <div class="last-fleximg">
    //           <img
    //           src="/assets/img/reports/management/page3/d380146ee98e4ce28d371ad733ff3dc71315-a3m-200h.png"
    //           alt="d380146ee98e4ce28d371ad733ff3dc71315"
    //           class="management-report-page3-hair-scalp-treatment-recommendat-d380146ee98e4ce28d371ad733ff3dc7"/>

    //          </div>
    //         <div class="last-flextxt">
    //           <span class="management-report-page3-hair-scalp-treatment-recommendat-text182">
    //            Guidelines for to Avoid
    //           </span>
    //           <div class="management-report-page3-hair-scalp-treatment-recommendat-frame2147224100">
    //            <span class="management-report-page3-hair-scalp-treatment-recommendat-text185">
    //              Hair breakage
    //            </span>
    //          </div>
    //        </div>
    //      </div>
    //      <div class="lastsec-txt3">
    //       <span
    //       class="management-report-page3-hair-scalp-treatment-recommendat-text229"
    //     >
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text230"
    //       >
    //         Experiencing excessive hair breakage is worrying, but addressing
    //         it promptly can prevent further damage. Here's how to handle it:
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text232"
    //       >
    //         1.Reduce Heat and Chemical Damage
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text233"
    //       >
    //         : Limit heat styling and always use a heat protectant, while
    //         giving your hair breaks from chemical treatments to prevent damage
    //         and promote recovery.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text235"
    //       >
    //         2.Use Gentle, Hydrating Products
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text236"
    //       >
    //         : Opt for sulfate-free shampoos and conditioners that provide
    //         nourishment and hydration to fragile hair.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text238"
    //       >
    //         3.Incorporate Regular Deep Conditioning
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text239"
    //       >
    //         : Use deep conditioning masks with ingredients like keratin or
    //         coconut oil weekly to strengthen and moisturize hair.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text241"
    //       >
    //         4.Trim Split Ends Regularly
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text242"
    //       >
    //         : Get trims every 6 to 8 weeks to remove split ends and prevent
    //         breakage from spreading.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text244"
    //       >
    //         5.Avoid Tight Hairstyles
    //       </span>
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text245"
    //       >
    //         : Choose looser styles to reduce tension and stress on the hair,
    //         preventing further breakage.
    //       </span>
    //       <br />
    //       <span
    //         class="management-report-page3-hair-scalp-treatment-recommendat-text247"
    //       >
    //         6.Protect Hair While Sleeping
    //       </span>
    //       <span>
    //         : Sleep on satin or silk pillowcases to minimize friction and
    //         reduce the risk of breakage.
    //       </span>
    //       <br />
    //       </span>
    //       </div>
    //       <div class="last-border4"></div>
    //     </div>

    //   </div>

    //   </div>
    //   </div>
  );
}
